import React from 'react';
import styled from 'styled-components';
import {
  IconBoxGridWidget as IProps,
} from 'infrastructure/gatsby/types/icon-box-grid';
import IconBoxGridComponent
  from '@shipae/components-sandbox/component/icon-box-grid';
import IconBoxGridTheme
  from '@shipae/components-sandbox/component/icon-box-grid/theme';
import { paleGrey } from '@shipae/components-sandbox/component/colors';
import { media, fonts } from 'views/theme';

const Wrapper = styled.section`
  background: ${ paleGrey() };
`;

const MainContainer = styled.section`
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 11.6rem 0;
`;

const Heading = styled.h2`
  margin: 0 0 2.4rem 0;
  text-align: center;
  ${ fonts.displaym200 }
`;

const Text = styled.div`
  text-align: center;
  margin: 0 10rem 2.4rem 10rem;
  line-height: 150%;

  ${ media.sm } {
    margin: 0 0 2.4rem;
  }
`;

const CustomIconBoxNarrowWithText = styled(
  IconBoxGridTheme.IconBoxNarrowWithText.Main,
)`
  ${ media.max(1600) } {
    width: 24rem;
    margin: 1.3rem;
  }

  ${ media.max(1500) } {
    width: 22rem;
  }

  ${ media.max(1300) } {
    width: 20rem;
  }

  ${ media.sm } {
    width: 18rem;
  }
`;

const CustomIconBoxSmall = styled(IconBoxGridTheme.IconBoxSmall.Main)`
  ${ media.max(1600) } {
    width: 16rem;
    margin: 2rem;
  }

  ${ media.max(1500) } {
    width: 15rem;
    margin: 1.6rem;
  }

  ${ media.max(1400) } {
    width: 14rem;
    margin: 1.4rem;
  }

  ${ media.max(1400) } {
    width: 13rem;
    margin: 1.2rem;
  }

  ${ media.max(1100) } {
    width: 13rem;
    margin: 0.8rem;
  }
`;

const CustomIconBoxLargeWithText = styled(
  IconBoxGridTheme.IconBoxLargeWithText.Main,
)`
  ${ media.max(1600) } {
    width: 35rem;
    margin: 1.3rem;
  }

  ${ media.max(1400) } {
    width: 33rem;
    margin: 1rem;
  }

  ${ media.max(1400) } {
    width: 30rem;
    margin: 1rem;
  }
`;

const CustomIconBoxGridTheme = {
  ...IconBoxGridTheme,
  IconBoxLargeFixedHeight: {
    ...IconBoxGridTheme.IconBoxLargeFixedHeight,
    Main: styled(IconBoxGridTheme.IconBoxLargeFixedHeight.Main)`
      ${ media.max(1600) } {
        width: 22.8rem;
        height: 21rem;
        margin: 1.6rem;
      }

      ${ media.max(1300) } {
        width: 22rem;
        height: 21rem;
        margin: 1rem;
      }

      ${ media.max(1100) } {
        width: 20rem;
        height: 20rem;
        margin: 1rem;
      }

      ${ media.md } {
        width: 20rem;
        height: 20rem;
        margin: 1rem;
      }
    `,
  },
  IconBoxLargeWithText: {
    ...IconBoxGridTheme.IconBoxLargeWithText,
    Main: CustomIconBoxLargeWithText,
  },
  IconBoxNarrowWithText: {
    ...IconBoxGridTheme.IconBoxNarrowWithText,
    Main: CustomIconBoxNarrowWithText,
  },
  IconBoxSmall: {
    ...IconBoxGridTheme.IconBoxSmall,
    Main: CustomIconBoxSmall,
  },
};

const IconBoxGrid: React.FC<IProps> = ({
  sectionId,
  ...content
}) => {
  const items = content.iconBoxes?.map((iconBox) => ({
    iconSrc: iconBox.icon?.localFile?.publicURL,
    iconAlt: iconBox.icon?.alternativeText,
    label: iconBox.label,
    text: iconBox.text,
  })) || [];

  return (
    <Wrapper id={sectionId}>
      <MainContainer>
        <Heading>{content.title}</Heading>
        <Text>{content.text}</Text>
        <IconBoxGridComponent
          boxType={content.iconBoxType}
          items={items}
          theme={CustomIconBoxGridTheme}
        />
      </MainContainer>
    </Wrapper>
  );
};

export default IconBoxGrid;
